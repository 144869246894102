'use client';

import Image from 'next/image';
import { Markdown, useSiteWideContext } from 'ui';

import styles from './not-found.module.scss';

export default function NotFoundTempate() {
  const { selectedDispensary } = useSiteWideContext();
  const shopLink = selectedDispensary?.shopLink || '/';

  return (
    <div className={styles.NotFound}>
      <div className="content-wrapper">
        <Markdown
          content={`#404\n\nOops! This page seems to be missing. It could have moved, or may have been deleted.\n\n- [Return to home page >](${shopLink})`}
        />
      </div>
      <div className={styles.imageWrapper}>
        <Image
          src="https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt10e5669136e3d259/6216adff8e2f9e3316bcfa45/not-found.png"
          width={1024}
          height={599}
          alt={''}
        />
      </div>
    </div>
  );
}
